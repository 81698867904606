import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './initFabricFonts';
import './i18n';
import './index.css';
import { App } from './App';
import { initializeIcons } from '@fluentui/react';

initializeIcons('/assets/fluentui/fonts/');

ReactDOM.render(
    <React.StrictMode>
        <Suspense fallback={<div></div>}>
            <App />
        </Suspense>
    </React.StrictMode>,
    document.getElementById('root')
);
