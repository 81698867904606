import {
    Stack, Text, useTheme
} from '@fluentui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    NAVBAR_HEIGHT, SIDE_PADDING_DESKTOP, SIDE_PADDING_MOBILE, SIDE_PADDING_TABLET, makeResponsive
} from '../../consts';

interface IFirstSectionProps {
}

const FirstSection: React.VFC<IFirstSectionProps> = (props) => {
    const { t: translate } = useTranslation('common');
    const theme = useTheme();

    return (
        <Stack className='first-section'
            verticalAlign='center'
            styles={{
                root: {
                    width: '100%',
                    minHeight: `calc(100vh - ${NAVBAR_HEIGHT})`,
                    paddingBottom: '3rem'
                }
            }}>
            <img src='/assets/innostack-header_wave.svg' alt={translate('CONTENT.FIRST_SECTION.BG_ALT')} style={{
                minWidth: '100%',
                minHeight: '100vh',
                objectFit: 'cover',
                marginTop: `calc(-1 * ${NAVBAR_HEIGHT})`
            }}/>
            <Stack className='title'
                verticalAlign='center'
                tokens={{
                    childrenGap: '.5rem'
                }}
                styles={{
                    root: {
                        ...makeResponsive({
                            paddingLeft: SIDE_PADDING_MOBILE
                        },
                        {
                            paddingLeft: SIDE_PADDING_TABLET
                        },
                        {
                            paddingLeft: SIDE_PADDING_DESKTOP
                        }),
                        position: 'absolute',
                        top: 0,
                        height: '100vh'
                    }
                }}>
                <h1
                    style={{
                        margin: 0
                    }}
                ><Text className='title'
                        styles={{
                            root: {
                                color: theme.palette.white,
                                ...makeResponsive(
                                    {
                                        fontSize: 'calc(3.5rem + 2vw)'
                                    },
                                    {
                                        fontSize: 'calc(4rem + 2vw)'
                                    },
                                    {
                                        fontSize: 'calc(5rem + 2vw)'
                                    }
                                )
                            }
                        }}>
                        {translate('CONTENT.FIRST_SECTION.TITLE')}
                    </Text>
                </h1>
                <h2 style={{
                    margin: 0
                }}><Text className='slogan'
                        styles={{
                            root: {
                                color: theme.palette.white,
                                ...makeResponsive(
                                    {
                                        fontSize: 'calc(1rem + 2vw)'
                                    },
                                    {
                                        fontSize: 'calc(2rem + 2vw)'
                                    }
                                )
                            }
                        }}>
                        {translate('CONTENT.FIRST_SECTION.SLOGAN')}
                    </Text>
                </h2>
                <h3 style={{
                    margin: 0
                }}><Text className='slogan'
                        styles={{
                            root: {
                                color: theme.palette.white,
                                fontWeight: 200,
                                ...makeResponsive(
                                    {
                                        fontSize: 'calc(1rem + 1vw)',
                                        width: '80%'
                                    },
                                    {
                                        width: '70%'
                                    },
                                    {
                                        width: '60%'
                                    }
                                )
                            }
                        }}>
                        {translate('CONTENT.FIRST_SECTION.SUBTITLE')}
                    </Text>
                </h3>
            </Stack>
        </Stack>
    );
};

export default FirstSection;
