import React from 'react';
import { Stack } from '@fluentui/react';
import { IPageProps } from '../../App';
import FirstSection from './FirstSection';
import ServicesSection from './ServicesSection';
import Navigation from '../../components/Navigation';
import Footer from '../../components/Footer';

const HomePage: React.VFC<IPageProps> = () => {
    return <Stack className='home-page'
        grow
        styles={{
            root: {
                width: '100%'
            }
        }}
    >
        <Navigation docked/>
        <FirstSection/>
        <ServicesSection/>
        <Footer/>
    </Stack>;
};

export default HomePage;
