import {
    Icon,
    Stack,
    Text,
    useTheme
} from '@fluentui/react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    makeResponsive,
    makeResponsiveWithBreakpoints, responsivePadding
} from '../../consts';
import CustomNavLink from '../../components/CustomNavLink';

interface IServicesSectionProps {
}
interface IService {
    key: string,
    iconName: string,
    moreLink?: string
}

const services: IService[] = [
    {
        key: 'software',
        iconName: 'ScaleVolume'
    },
    {
        key: 'sap',
        iconName: 'Education'
    },
    {
        key: 'webapps',
        iconName: 'WebComponents'
    },
    {
        key: 'portals',
        iconName: 'Teamwork'
    },
    {
        key: 'energy',
        iconName: 'Financial'
    },
    {
        key: 'commodities',
        iconName: 'Diamond'
    },
    {
        key: 'fsm',
        iconName: 'CRMServices'
    },
    {
        key: 'desktop',
        iconName: 'System'
    }
];

const ServicesSection: React.VFC<IServicesSectionProps> = () => {
    const { t: translate } = useTranslation('common');
    const theme = useTheme();

    const serviceComponents = useMemo(() => {
        return services.map((service) => {
            return <Stack key={service.key} className='service'
                tokens={{
                    childrenGap: '1rem'
                }}
                styles={{
                    root: {
                        backgroundColor: theme.palette.white,
                        borderRadius: '.5rem',
                        padding: '0 2rem 2rem 2rem',
                        ...makeResponsiveWithBreakpoints({
                            maxWidth: '900px',
                            style: {
                                width: '100%'
                            }
                        }, {
                            maxWidth: '1800px',
                            style: {
                                width: 'calc(50% - 2rem)'
                            }
                        }, {
                            width: 'calc(25% - 2rem)'
                        })
                    }
                }}>
                <Stack className='icon' horizontalAlign='start' styles={{
                    root: {
                        position: 'relative',
                        width: '100%',
                        height: '8rem'
                    }
                }}>
                    <img
                        src='/assets/innostack-Iconbox_circle_basic.svg'
                        alt={translate('CONTENT.SERVICES.CIRCLE_ALT')}
                        style={{
                            height: '100%'
                        }} />
                    <Icon iconName={service.iconName}
                        styles={{
                            root: {
                                position: 'absolute',
                                top: '2.7rem',
                                left: '2.2rem',
                                color: theme.palette.white,
                                fontSize: '3.2rem'
                            }
                        }} />
                </Stack>
                <Text className='title' variant='xLargePlus'>
                    {translate(`CONTENT.SERVICES.LIST.${service.key.toUpperCase()}.TITLE`)}
                </Text>
                <Text className='description'
                    variant='large'
                    styles={{
                        root: {
                            fontWeight: 300
                        }
                    }}>
                    {translate(`CONTENT.SERVICES.LIST.${service.key.toUpperCase()}.DESCRIPTION`)}
                </Text>
                {service.moreLink && <CustomNavLink to='/'>
                    {() => {
                        return <><Text variant='large'
                            styles={{
                                root: {
                                    color: theme.palette.themePrimary
                                }
                            }}>
                            {translate(`CONTENT.SERVICES.MORE`)}
                        </Text>
                        <Icon iconName='IncreaseIndentArrow' style={{
                            fontSize: '2rem',
                            height: '1.6rem',
                            paddingLeft: '1rem',
                            color: theme.palette.themePrimary
                        }} />
                        </>;
                    }}
                </CustomNavLink>}

            </Stack>;
        });
    }, [theme, translate]);

    return (
        <Stack className='services-section' styles={{
            root: {
                width: '100%',
                paddingTop: '3rem',
                paddingBottom: '3rem',
                ...responsivePadding
            }
        }}>
            <Stack className='service-title' styles={{
                root: {
                    paddingBottom: '2rem'
                }
            }}>
                <h4><Text variant='xLarge' styles={{
                    root: {
                        fontSize: '1.5rem'
                    }
                }}>{translate('CONTENT.SERVICES.SERVICE_OVERVIEW')}</Text></h4>
                <Text variant='xxLargePlus' styles={{
                    root: {
                        color: theme.palette.themePrimary,
                        ...makeResponsive({
                            fontSize: '1.7rem'
                        }, {
                            fontSize: '2.5rem'
                        })
                    }
                }}>{translate('CONTENT.SERVICES.SERVICE_OVERVIEW_SUBTITLE')}</Text>
            </Stack>
            <Stack horizontal wrap className='services-overview'
                tokens={{
                    childrenGap: '2rem'
                }}>
                {serviceComponents}
            </Stack>
        </Stack>
    );
};

export default ServicesSection;
