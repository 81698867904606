import {
    IEffects,
    IFontStyles, IPalette, PartialTheme
} from '@fluentui/react';

const FONT_STYLE: Partial<IFontStyles> = {
    tiny: {
        fontSize: '.375rem'
    },
    xSmall: {
        fontSize: '.5rem'
    },
    small: {
        fontSize: '.625rem'
    },
    smallPlus: {
        fontSize: '.75rem',
        fontWeight: 'bold'
    },
    medium: {
        fontSize: '.875rem'
    },
    mediumPlus: {
        fontSize: '1rem',
        fontWeight: 'bold'
    },
    large: {
        fontSize: '1.125rem',
        fontWeight: 'semibold'
    },
    xLarge: {
        fontSize: '1.25rem',
        fontWeight: 'semibold'
    },
    xxLarge: {
        fontSize: '1.5rem',
        fontWeight: 'semibold'
    },
    superLarge: {
        fontSize: '1.75rem',
        fontWeight: 'semibold'
    },
    mega: {
        fontSize: '2rem',
        fontWeight: 'semibold'
    }
};

const PALETTE_LIGHT: Partial<IPalette> = {
    themePrimary: '#37A7DF', //
    themeLighterAlt: '#f6f7fe',
    themeLighter: '#dddffb',
    themeLight: '#c0c3f8',
    themeTertiary: '#F8F8F8',
    themeSecondary: '#C2C2C2', //
    themeDarkAlt: '#323dd1',
    themeDark: '#1D70B7', //
    themeDarker: '#192642', //
    neutralLighterAlt: '#ffffff',
    neutralLighter: '#ffffff',
    neutralLight: '#ffffff',
    neutralQuaternaryAlt: '#ffffff',
    neutralQuaternary: '#ffffff',
    neutralTertiaryAlt: '#ffffff',
    neutralTertiary: '#595956',
    neutralSecondary: '#FFB300',
    neutralSecondaryAlt: '#FFCD57',
    neutralPrimaryAlt: '#2f2f2d',
    neutralPrimary: '#000000',
    neutralDark: '#151514',
    black: '#0b0b0a',
    white: '#ffffff'
};

const EFFECT_ELEVATION_OPACITY_LIGHT = '50';

const EFFECTS_LIGHT: Partial<IEffects> = {
    elevation4: `0 1.6px 3.6px 0 ${PALETTE_LIGHT.black}${EFFECT_ELEVATION_OPACITY_LIGHT}, 0 0.3px 0.9px 0 ${PALETTE_LIGHT.black}${EFFECT_ELEVATION_OPACITY_LIGHT}`,
    elevation8: `0 3.2px 7.2px 0 ${PALETTE_LIGHT.black}${EFFECT_ELEVATION_OPACITY_LIGHT}, 0 0.6px 1.8px 0 ${PALETTE_LIGHT.black}${EFFECT_ELEVATION_OPACITY_LIGHT}`,
    elevation16: `0 6.4px 14.4px 0 ${PALETTE_LIGHT.black}${EFFECT_ELEVATION_OPACITY_LIGHT}, 0 1.2px 3.6px 0 ${PALETTE_LIGHT.black}${EFFECT_ELEVATION_OPACITY_LIGHT}`,
    elevation64: `0 25.6px 57.6px 0 ${PALETTE_LIGHT.black}${EFFECT_ELEVATION_OPACITY_LIGHT}, 0 4.8px 14.4px 0 ${PALETTE_LIGHT.black}${EFFECT_ELEVATION_OPACITY_LIGHT}`
};

export const THEME_LIGHT: PartialTheme = {
    fonts: FONT_STYLE,
    palette: PALETTE_LIGHT,
    effects: EFFECTS_LIGHT
};
