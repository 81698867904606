import React from 'react';
import { Text } from '@fluentui/react';
import { useTranslation } from 'react-i18next';

const HRBInfos: React.VFC<{}> = () => {
    const { t: translate } = useTranslation('common');

    return <>
        <Text>{translate('INFOS.TRADE_REGISTER_NO')}</Text>
        <Text>{translate('INFOS.TRADE_REGISTER_LOCAL')}</Text>
    </>;
};

export default HRBInfos;
