import {
    createTheme,
    ThemeProvider
} from '@fluentui/react';
import React, { useEffect } from 'react';
import {
    BrowserRouter,
    Route, Routes,
    Navigate
} from 'react-router-dom';
import HomePage from './app/home/HomePage';
import ImprintPage from './app/imprint/ImprintPage';
import AppWrapper from './AppWrapper';
import { THEME_LIGHT } from './theme';
import { useTranslation } from 'react-i18next';
import VCardPage from './app/vcard/VCardPage';


export interface IPageProps {
}

export const App: React.VFC = () => {
    const { t: translate } = useTranslation('common');

    useEffect(() => {
        document.title = translate('APP.TITLE');
        const metaElements = document.getElementsByTagName('meta');

        for (let index = 0; index < metaElements.length; index++) {
            const metaElement = metaElements[index];

            if (metaElement.getAttribute('name') === 'description') {
                metaElement.setAttribute('content', translate('APP.DESC'));
            }
        }
    }, [translate]);

    return (
        <ThemeProvider theme={createTheme(THEME_LIGHT)}>
            <BrowserRouter>
                <AppWrapper>
                    <Routes>
                        <Route path='*' element={
                            <Routes>
                                <Route path='/' element={
                                    <HomePage/>
                                }/>
                                <Route path='/imprint' element={
                                    <ImprintPage/>
                                }/>
                                <Route path='/vcard/:name' element={
                                    <VCardPage/>
                                }/>
                                <Route path='*' element={<Navigate to={'/'} />}/>
                            </Routes>
                        }>
                        </Route>
                    </Routes>
                </AppWrapper>
            </BrowserRouter>
        </ThemeProvider>
    );
};
