import { IRawStyle } from '@fluentui/react';

const MAX_WIDTH_MOBILE = '600px';
const MAX_WIDTH_TABLET = '1200px';

// media queries
export const darkmodePreference = window && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

// fixed consts
export const NAVBAR_HEIGHT = 'calc(3rem + 2vw)';

export const SIDE_PADDING_DESKTOP = '5rem';
export const SIDE_PADDING_TABLET = '2rem';
export const SIDE_PADDING_MOBILE = '1rem';

export const HEIGHT_PADDING_NAV_DESKTOP = '1rem';
export const HEIGHT_PADDING_NAV_TABLET = '1rem';
export const HEIGHT_PADDING_NAV_MOBILE = '1rem';

export function makeResponsiveWithBreakpoints(mobile: {maxWidth: string, style: IRawStyle}, tablet: {maxWidth: string, style: IRawStyle}, desktop?: IRawStyle): IRawStyle {
    const { style } = mobile;

    style[`@media screen and (${mobile.maxWidth} < width <= ${tablet.maxWidth})`] = tablet.style;

    style[`@media screen and (width >= ${tablet.maxWidth})`] = desktop || tablet.style;


    return style;
}

export function makeResponsive(mobile: IRawStyle, tablet: IRawStyle, desktop?: IRawStyle): IRawStyle {
    return makeResponsiveWithBreakpoints(
        {
            style: mobile,
            maxWidth: MAX_WIDTH_MOBILE
        },
        {
            style: tablet,
            maxWidth: MAX_WIDTH_TABLET
        },
        desktop
    );
}
export const responsivePadding = makeResponsive({
    paddingLeft: SIDE_PADDING_MOBILE,
    paddingRight: SIDE_PADDING_MOBILE
},
{
    paddingLeft: SIDE_PADDING_TABLET,
    paddingRight: SIDE_PADDING_TABLET
},
{
    paddingLeft: SIDE_PADDING_DESKTOP,
    paddingRight: SIDE_PADDING_DESKTOP
});
