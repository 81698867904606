import {
    Stack, useTheme, Text, IRawStyle, ActionButton, IButtonStyles
} from '@fluentui/react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CustomNavLink from './CustomNavLink';
import {
    makeResponsive, responsivePadding
} from '../consts';
import HideFromBots, {
    HiddenLinkType, hideFromBotsOnClick
} from './HideFromBots';

interface IFooterProps {
}

const Footer: React.VFC<IFooterProps> = () => {
    const theme = useTheme();
    const { t: translate } = useTranslation('common');

    const lowerFooter = useMemo(() => {
        const fontStyle: IRawStyle = {
            color: theme.palette.themeSecondary,
            fontWeight: 300
        };

        return <Stack className='lower-footer' horizontal verticalAlign='end' styles={{
            root: {
                height: '5rem',
                paddingBottom: '1rem',
                ...responsivePadding
            }
        }}>
            <Text styles={{
                root: fontStyle
            }}>
                {translate('FOOTER.COPYRIGHT')
                    .replace('{YEAR}', new Date().getFullYear()
                        .toString())}</Text>
            <Stack grow horizontalAlign='end' horizontal tokens={{
                childrenGap: '1rem'
            }}>
                {/* <CustomNavLink to='/terms'>
                    {() => {
                        return <Text styles={{
                            root: fontStyle
                        }}>{translate('FOOTER.PRIVACY')}</Text>;
                    }}
                </CustomNavLink>
                <CustomNavLink to='/privacy'>
                    {() => {
                        return <Text styles={{
                            root: fontStyle
                        }}>{translate('FOOTER.TERMS')}</Text>;
                    }}
                </CustomNavLink> */}
                <CustomNavLink to='/imprint'>
                    {() => {
                        return <Text styles={{
                            root: fontStyle
                        }}>{translate('FOOTER.IMPRINT')}</Text>;
                    }}
                </CustomNavLink>
            </Stack>
        </Stack>;
    }, [theme, translate]);

    const contactUs = useMemo(() => {
        const buttonStyles: IButtonStyles = {
            root: {
                color: theme.palette.neutralSecondary,
                border: '1px solid',
                borderColor: theme.palette.neutralSecondary,
                borderRadius: '.25rem',
                height: '3rem',
                ...makeResponsive({
                    fontSize: '1rem',
                    padding: '1rem',
                    margin: '.5rem'
                },
                {
                    fontSize: '1rem',
                    padding: '1rem',
                    margin: 0
                },
                {
                    fontSize: '1rem',
                    padding: '1rem',
                    margin: '1rem 0'
                })
            },
            rootHovered: {
                color: theme.palette.neutralSecondary
            },
            icon: {
                color: theme.palette.neutralSecondary,
                paddingRight: '.5rem'
            },
            iconHovered: {
                color: theme.palette.neutralSecondary
            }
        };

        return <Stack styles={{
            root: {
                position: 'relative',
                height: '34rem'

            }
        }}>
            <Stack className='contact' horizontalAlign='center' styles={{
                root: {
                    position: 'absolute',
                    zIndex: 10,
                    width: '100%',
                    height: '17rem',
                    top: '8.5rem'
                }
            }}>
                <Stack horizontal verticalAlign='center' styles={{
                    root: {
                        width: '80%',
                        height: '100%',
                        backgroundColor: theme.palette.themeDarker,
                        borderRadius: '1rem',
                        padding: '2rem',
                        ...makeResponsive({
                            flexDirection: 'column'
                        },
                        {
                            flexDirection: 'column'
                        },
                        {
                            flexDirection: 'row'
                        })
                    }
                }}>
                    <Stack className='text' grow>
                        <Text className='subtitle' variant='xxLarge' styles={{
                            root: {
                                color: theme.palette.white,
                                fontWeight: '200',
                                ...makeResponsive(
                                    {
                                        fontSize: 'calc(.5rem + 1vw)'
                                    },
                                    {
                                        fontSize: 'calc(.6rem + 1vw)'
                                    },
                                    {
                                        fontSize: 'calc(.6rem + 1vw)'
                                    }
                                )
                            }
                        }}>
                            {translate('FOOTER.SUBTITLE')}
                        </Text>
                        <Text className='title' variant='xxLargePlus' styles={{
                            root: {
                                color: theme.palette.white,
                                ...makeResponsive(
                                    {
                                        fontSize: 'calc(1rem + 1vw)'
                                    },
                                    {
                                        fontSize: 'calc(1.5rem + 1vw)'
                                    },
                                    {
                                        fontSize: 'calc(1.5rem + 1vw)'
                                    }
                                )
                            }
                        }}>
                            {translate('FOOTER.TITLE')}
                        </Text>
                    </Stack>
                    <Stack className='contact-buttons'
                        verticalAlign='center'
                        styles={{
                            root: {
                                ...makeResponsive({
                                    flexDirection: 'row',
                                    width: '100%',
                                    height: 'unset',
                                    paddingTop: '1rem',
                                    justifyContent: 'space-evenly',
                                    flexWrap: 'wrap'
                                },
                                {
                                    flexDirection: 'row',
                                    width: '100%',
                                    height: 'unset',
                                    paddingTop: '1rem',
                                    justifyContent: 'space-evenly',
                                    flexWrap: 'nowrap'
                                }, {
                                    flexDirection: 'column',
                                    padding: '2rem',
                                    height: '100%',
                                    width: 'unset',
                                    justifyContent: 'center'
                                })
                            }
                        }}>
                        <ActionButton
                            className='phone'
                            onClick={() => { hideFromBotsOnClick(translate('INFOS.COMPANY_PHONE_CALLABLE_BASE64'), HiddenLinkType.Tel); }}
                            iconProps={{
                                iconName: 'Phone'
                            }}
                            styles={buttonStyles}>
                            <HideFromBots base64Data={translate('INFOS.COMPANY_PHONE_BASE64')}/>
                        </ActionButton>
                        {/* {translate('INFOS.COMPANY_PHONE')} */}
                        <ActionButton
                            className='mail'
                            onClick={() => { hideFromBotsOnClick(translate('INFOS.CONTACT_MAIL_BASE64'), HiddenLinkType.Mail); }}
                            iconProps={{
                                iconName: 'Mail'
                            }}
                            styles={buttonStyles}>
                            <HideFromBots base64Data={translate('INFOS.CONTACT_MAIL_BASE64')}/>
                        </ActionButton>
                    </Stack>
                </Stack>
            </Stack>
            <Stack className='light-half' styles={{
                root: {
                    backgroundColor: theme.palette.themeTertiary,
                    height: '50%'
                }
            }}/>
            <Stack className='gradient-half' styles={{
                root: {
                    background: `linear-gradient(0deg, ${theme.palette.themeDarker} 0%, ${theme.palette.themeDark} 100%)`,
                    height: '50%'
                }
            }}/>
        </Stack>;
    }, [theme.palette.neutralSecondary, theme.palette.themeDark, theme.palette.themeDarker, theme.palette.themeTertiary, theme.palette.white, translate]);

    return (
        <Stack className='footer' styles={{
            root: {
                backgroundColor: theme.palette.themeDarker

            }
        }}>
            {contactUs}
            {lowerFooter}
        </Stack>
    );
};

export default Footer;
