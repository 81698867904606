import React from 'react';
import { Text } from '@fluentui/react';
import { useTranslation } from 'react-i18next';

const CompanyInfos: React.VFC<{}> = () => {
    const { t: translate } = useTranslation('common');

    return <>
        <Text>{translate('INFOS.COMPANY')}</Text>
        <Text>{translate('INFOS.ADDRESS_L1')}</Text>
        <Text>{translate('INFOS.ADDRESS_L2')}</Text>
    </>;
};

export default CompanyInfos;
