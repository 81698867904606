import React, { useMemo } from 'react';

export enum HiddenLinkType {
    Mail,
    Tel
}

interface IHideFromBotsProps {
    base64Data: string,
    wrapLetter?: (content: string, style: React.CSSProperties, key: string) => JSX.Element,
    containerStyle?: React.CSSProperties,
    containerClass?: string
}

export function b64_to_utf8(str: string) {
    return decodeURIComponent(window.atob(str));
}

export function utf8_to_b64(str: string) {
    return window.btoa(encodeURIComponent(str));
}

const HideFromBots: React.VFC<IHideFromBotsProps> = (props) => {
    const { base64Data, containerClass, containerStyle, wrapLetter } = props;

    const content = useMemo(() => {
        if (!base64Data) {
            return [];
        }

        try {
            const data = b64_to_utf8(base64Data);

            let text: {
                letter: JSX.Element,
                 size: string
                }[] = [];

            const defaultStyle: React.CSSProperties = {};


            for (let i = 0; i < data.length; i++) {
                const letterData = data[i];
                const style: React.CSSProperties = {
                    ...defaultStyle,
                    gridRowStart: '1',
                    gridRowEnd: '1',
                    gridColumnStart: `${i + 1}`,
                    gridColumnEnd: `${i + 2}`
                };

                const letter: {
                    letter: JSX.Element,
                     size: string
                    } = {
                        letter: wrapLetter
                            ? wrapLetter(letterData, style, `${i}`)
                            : <span key={i} style={style} dangerouslySetInnerHTML={{
                                __html: letterData === ' ' ? '&nbsp;' : letterData
                            }}/>,
                        size: letterData === ' ' ? 'auto' : 'auto' // TODO fix spacing
                    };

                if (i % 2 === 0) {
                    text = [...text, letter];
                } else {
                    text = [letter, ...text];
                }
            }

            return text;
        } catch {
            console.error('can not parse b64: ', base64Data);

            return [];
        }
    }, [base64Data, wrapLetter]);

    return (
        <span className={containerClass} style={{
            ...containerStyle,
            display: 'inline-grid',
            gridTemplateColumns: content.map((letter) => { return letter.size; }).join(' '),
            gridTemplateRows: '1fr'
        }}>
            {content.map((letter) => { return letter.letter; })}
        </span>
    );
};

export function hideFromBotsOnClick(base64Data : string, type: HiddenLinkType) {
    let prefix = '';

    if (!base64Data) {
        return;
    }

    switch (type) {
        case HiddenLinkType.Mail:
            prefix = 'bWFpbHRvJTNB';

            break;
        case HiddenLinkType.Tel:
            prefix = 'dGVsJTNB';

            break;
    }
    try {
        const data = b64_to_utf8(prefix) + b64_to_utf8(base64Data);

        const frame = document.createElement('iframe');

        frame.style.display = 'hidden';
        const link = document.createElement('a');

        link.style.display = 'hidden';

        link.href = data;

        frame.appendChild(link);
        document.body.appendChild(frame);
        link.click();
        document.body.removeChild(frame);
    } catch {
        console.error('can not parse b64: ', base64Data);
    }
}

export default HideFromBots;
